<template>
   <div>
   
     <b-row>
          <b-col  >
            <p align="left" >
                  <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Pesquise aqui..."
                            >
                            </b-form-input>
                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                            </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
              </p>
          </b-col> 

          <b-col >
            <p align="left" class="pesquisa_select" >
                        <b-button @click="createItem"  pill 
                        variant="success" 
                        size="sm">Novo Registro</b-button>
                 <!-- <b-button @click="pesquisarExame" pill variant="success" size="sm"> Pesquisar </b-button> -->
                       &nbsp;
                     <b-button  type="button" scale="2" variant="outline-success"  v-on:click="download" ><b> Excel</b> <b-icon icon="file-excel" ></b-icon></b-button>
            </p>
          </b-col>
     </b-row>

     <b-table striped hover responsive
         id="my-table"
        :busy="isBusy"
        :items="tableExame" 
        :filter="filter" 
        :fields="visibleFields"
        :per-page="perPage"
        :current-page="currentPage"
        aria-controls="my-table"
        small
        >

            <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
            </template>

            <template #cell(status)="data">
                      <b-icon 
                          :icon="iconStatus(data.item)" 
                          cursor= "pointer" 
                          :variant="iconStatusVariant(data.item)"
                          size="sm">
                      </b-icon>
            </template>

            <template #cell(opcao)="data">
                <b-icon icon="pencil-fill" 
                    cursor= "pointer" 
                    @click="editItem(data.item)" pill 
                    variant="primary" 
                    size="sm">
                </b-icon>
                    &nbsp;
                <b-icon icon="trash" 
                    cursor= "pointer" 
                    @click="deleteItem(data.item)" pill 
                    v-b-modal="'edit-modal'" 
                    variant="danger" 
                    :hidden="iconVisible(user.perfil)"
                    size="sm">
                </b-icon>
            </template>

      </b-table>

           <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>

          <!-- Info modal -->
           <b-modal v-model="modalShow" size="lg"  hide-footer>
            <b-form @submit.prevent="save">

                         <b-form-group class="mb-3" label="Procedimentos:" >
                            <v-select :options="tableProcedimentos"  :reduce="(option) => option.id" label="name"  v-model="formdata.procedimento_id" ></v-select>
                         </b-form-group>
                
                      <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
                        <b-form-input
                                id="input-2"
                                hidden="true"
                                v-model="formdata.id"
                                placeholder="id"
                        ></b-form-input>

                        <b-form-input
                                id="input-2"
                                v-model="formdata.name"
                                required
                                placeholder="Nome"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-3" label="Valor avista:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valoravista"
                              required
                              placeholder="avista"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-3" label="Valor ems:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valorems"
                              required
                              placeholder="ems"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-3" label="Valor médico:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valormedico"
                              required
                              placeholder="medico"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-2" label="Ativo:" label-for="checkbox-1" size="sm" switch>
                         <b-form-checkbox class="mb-3" id="checkbox-1" v-model="formdata.ativo" required placeholder="ativo"></b-form-checkbox>
                      </b-form-group>
                   
                      
                      <b-form-group id="localrealizacaoexame" label="Local Exame:*">
                    <b-form-select
                      id="sexo"
                      v-model="formdata.localrealizacaoexame"
                      :options="localRealizacaoExame"
                      required
                    ></b-form-select>
          </b-form-group>

                <br/>
                <b-button size="sm" pill variant="danger" @click="close" >
                    Cancelar
                </b-button>
                &nbsp;
                <b-button type="submit" pill size="sm" variant="success">
                    Salvar
                </b-button>
            </b-form>
        </b-modal>
          
    </div>
</template>


<script>

import {  mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import * as XLSX from 'xlsx'
export default {

        mixins:[maskMixins], 

        data () {
       
          return {
              // formdata:{
              //   procedimento_id: ''
              // },
              selected:'',
              isBusy: false,
              perPage: 20,
              currentPage: 1,
              filter: null,
              localRealizacaoExame: [
                        { text: "Selecione", value: null },
                        "EMS",
                        "Outro",
                      ],
             // procedimentos:{},
              infoModal: 
                        {
                          id: 'info-modal',
                          title: '',
                          content: ''
                        },
              fields:[  
                      {
                        key: 'status',
                        label:'Status',
                        sortable: true, 
                        thStyle: { width: "5%" },
                        visible: true
                      },
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "10%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'name',
                        label:'Exame',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "40%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'procedimento.codigoesocial',
                        label:'E-Social',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "10%"},
                        tdClass: 'fonteLinhasLeft'
                      },{
                        key: 'valoravista',
                        label:'Valor avista',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "10%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'valorems',
                        label:'Valor Ems',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "10%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'valormedico',
                        label:'Valor Médico',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "10%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'opcao',
                        visible: true,
                        thStyle: { width: "7%" },
                        label:'Opções',
                        sortable: true
                      }                               
                    ],
                   formdata:{},
                   modalShow: false,
                   editedIndex: -1
          }
        },
        
       mounted(){
                    this.ActionFindProcedimentos(),
                    this.ActionFindExame(),
                    setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                                     this.isBusy = false
                                    }, 2.0*2000)
                },

      computed: {
                  ...mapState('auth', ['user']),
                  visibleFields() {
                          return this.fields.filter(field => field.visible)
                            },
            

                  rows() {
                          return this.tableExame.length
                         },

                            ...mapState('exame', ['tableExame']),
                            ...mapState('procedimentos', ['tableProcedimentos'])
        },

      methods: {
                   ...mapActions('procedimentos', ['ActionFindProcedimentos']),
                   ...mapActions('exame', ['ActionFindExame']),
                   ...mapActions('exame', ['ActionSalvar']),
                   ...mapActions('exame', ['ActionEditar']),
                   ...mapActions('exame', ['ActionDeletar']),

                    iconVisible(perfil) {

                      if(perfil==='administrador'){
                            return false
                      }

                      else{
                          return true
                      }
                                          
                    },
                    download: function () {

                      const data = XLSX.utils.json_to_sheet(this.tableExame)
                      const wb = XLSX.utils.book_new()
                      XLSX.utils.book_append_sheet(wb, data, 'data')
                      XLSX.writeFile(wb, 'table_exame.xlsx')

                    },

                   iconStatusVariant(item){

                            if((item.valoravista == 1) || (item.valormedico == 1) || (item.valorems == 1)  ){
                              return 'danger'
                            }
                            else{
                                 return 'success'
                              }
                        },

                  iconStatus(item){
                              if((item.valoravista == 1) || (item.valormedico == 1) || (item.valorems == 1)  ){
                                    return 'x-circle'
                                    }
                              else  {
                                    return 'check-square'
                              }
                       },
                       
                  createItem() {

                        this.modalShow = true;
                        this.formdata = {  
                          ativo: 'true',
                          procedimento_id:''
                        };
                    //    this.procedimentos = this.tableProcedimentos
                        this.editedIndex = -1;
                    },

                    editItem(item) {

                        item.valoravista=this.converteFloatMoeda(item.valoravista);
                        item.valormedico=this.converteFloatMoeda(item.valormedico);
                        item.valorems=this.converteFloatMoeda(item.valorems);

                        this.modalShow = true;
                       // this.editedIndex = this.tableExame.indexOf(item);
                        this.editedIndex = this.fields.indexOf(item);
                        this.formdata = Object.assign({}, item);
                    },

                    close() {
                              this.modalShow = false;
                              setTimeout(() => {
                                this.formdata = {}
                                this.editedIndex = -1;
                                this.ActionFindExame()
                              }, 300);
                     },

              async save() {

                      try {
                      //  this.formdata.procedimento_id = this.formdata.procedimento_id.id
                      //converter valores form
                      this.formdata.valoravista=this.converteMoedaFloat(this.formdata.valoravista);
                      this.formdata.valormedico=this.converteMoedaFloat(this.formdata.valormedico);
                      this.formdata.valorems=this.converteMoedaFloat(this.formdata.valorems);
                    

                            if(!this.formdata.id){//entra para salvar
                                this.formdata.usuariocadastro = this.user.id
                                await this.ActionSalvar(this.formdata)
                                this.ActionFindExame()
                                alert('Cadastrado com sucesso!')
                            }

                            else{//entra para editar
                                  delete this.formdata.usuariocadastro
                                  this.formdata.usuarioedicao = this.user.id
                                  await this.ActionEditar(this.formdata)
                                  this.ActionFindExame()
                                  alert('Atualizado com sucesso!')
                                  this.close()
                            }

                        this.formdata = {
                          ativo: 'true'
                        }

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar produto')
                        }
                },  
              
              async deleteItem(data){

                            const index = this.tableExame.indexOf(data);  

                            if(confirm('Deseja excluir o item?') && this.tableExame.splice(index, 1) ){
                                await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                      },

                  mascaraCnpj(value) {
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

                  formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
                  resetInfoModal() {
                                      this.infoModal.title = ''
                                      this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}

</style>