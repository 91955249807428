<template>
  <div id="ListAsosExame">
    <b-row>
      <b-col>
        <p align="left">
          <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Pesquise aqui...">
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </p>
      </b-col>
    </b-row>
    <b-table striped hover responsive id="my-table" :items="listAsos" :fields="visibleFields" :per-page="perPage" :current-page="currentPage" aria-controls="my-table" small>
          <template #cell(opcao)="data">
            <b-icon icon="pencil-fill"
                          cursor= "pointer"
                          @click="editItem(data.item)" pill
                          variant="primary" 
                          size="sm">
                      </b-icon>
            &nbsp;
            <b-icon icon="broadcast-pin" cursor="pointer" :hidden="habilitaEsocial(data.item.empresa.esocial)"
              @click="enviarAso(data.item)" pill variant="success" size="sm">
            </b-icon>
          </template>
    </b-table>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
      <!-- Info modal -->
             <b-modal size="xl" v-model="modalShow" title="Questioário Ficha Clínica" hide-footer >
              <b-form @submit.prevent="save">
                  <b-table striped hover responsive id="my-table2" :items="listPerguntas" :fields="visibleFields2"  aria-controls="my-table2" >

                            <template #cell(resposta)="resposta">
                                          <b-form-select v-model="resposta.item.resposta"  
                                            :name="resposta.item.id"
                                            :id="resposta.item.id" 
                                            @change="saveResposta($event, resposta.item.id)"
                                            :value="resposta.item.resposta"
                                            :options="options">
                                          </b-form-select>
                            </template>

                            <template #cell(observacao)="observacao">
                                      <b-form-input
                                        :name="observacao.item.id"
                                        :id="observacao.item.id"
                                        :ref="observacao.item.id"
                                        v-on:blur="saveObservacao($event, observacao.item.id)"
                                        :value="observacao.item.observacao"
                                        type="text"
                                        placeholder=" "
                                        ></b-form-input>
                            </template>
                  </b-table>
              </b-form>
          </b-modal>
  </div>
</template>

<script>
const moment = require("moment");
import { http } from '@/plugins/axios'
import maskMixins from '@/helpers/mixins/mask'

export default {

  mixins: [maskMixins],
  data() {
    return {
      options:[
        { value: 'SIM', text: 'SIM' },
        { value: 'NAO', text: 'NAO' }
      ],
      isBusy: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      fields2: [
        {
          key: 'id',
          label: 'id',
          visible: false,
          sortable: true,
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'pergunta',
          label: 'Pergunta',
          visible: true,
          sortable: true,
          thStyle: { width: "40%" },
          tdClass: 'fonteLinhasLeft'
        },
          {
          key: 'resposta',
          visible: true,
          label: 'Resposta',
          thStyle: { width: "15%" },
          sortable: true
        },
         {
          key: 'observacao',
          visible: true,
          label: 'Observação',
          thStyle: { width: "45%" },
          sortable: true
        }
        
      ],

      fields: [
        {
          key: 'id',
          label: 'id',
          visible: false,
          sortable: true,
          thStyle: { width: "60%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'codigoaso',
          label: 'Código',
          visible: true,
          sortable: true,
          thStyle: { width: "5%" }

        },
        {
          key: 'paciente.nome',
          label: 'Paciente',
          sortable: true,
          visible: true,
          thStyle: { width: "25%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'paciente.cpf',
          label: 'Cpf',
          sortable: true,
          visible: true,
          thStyle: { width: "8%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'tipoaso.descricao',
          label: 'ASO',
          visible: true,
          sortable: true,
          thStyle: { width: "7%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'empresa.nome',
          label: 'Empresa',
          sortable: true,
          visible: true,
          thStyle: { width: "45%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'created_at',
          label: 'Cadastro Aso',
          sortable: true,
          visible: true,
          thStyle: { width: "15%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'resultado',
          label: 'Resultado',
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft'
        },

        {
          key: 'opcao',
          visible: true,
          label: 'Opções',
          sortable: true
        }
      ],
 
      formdataResposta: {
        resposta: ""
      },

      formdataObservacao: {
        observacao: ""
      },

      idfichaclinica:'',
      idfichaclinica2: '',
      campoObservacao:'',
      campoResposta:'',
      examesAso: [],
      xml: '',
      dadosAso: [],
      listPerguntas:[],
      listAsos: [],
      modalShow: false,
      editedIndex: -1
    }
  },
  mounted() {
 //   console.log(this.$refs.formdata.resposta.focus());
    this.ActionFindAsos(),
     //this.ActionFindPerguntas(),
      setTimeout(() => {// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
        this.isBusy = false
      }, 2.0 * 2000)
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
      visibleFields2() {
      return this.fields2.filter(field => field.visible)
    },
    rows() {
      return this.listAsos.length
    },
  },
  methods: {
    close() {
      this.modalShow = false;
      setTimeout(() => {
        this.formdata = {}
        this.editedIndex = -1;
        this.ActionFindFuncao()
      }, 300);
    },
    habilitaEsocial(item) {
      return item ? false : true
    },


    async ActionFindAsos() {
      await http.get('/aso').then(resp => {
        (resp.data).forEach(function (item) {//tratando as datas 
          item.created_at = moment(item.created_at).add(1, 'days').format("DD/MM/yyyy")
        })
        this.listAsos = resp.data
      })
    },

    async ActionFindPerguntas(aso_id) {
      await http.get('/fichaclinica/'+aso_id).then(resp => {
        // (resp.data).forEach(function (item) {//tratando as datas 
        //   item.created_at = moment(item.created_at).add(1, 'days').format("DD/MM/yyyy")
        // })
        this.listPerguntas = resp.data
      })
    },


    async saveResposta(event, id) {

      try {

        const resposta=event;
        const idpergunta = id;

       // console.log(event.target.options.selectedIndex)
      //  console.log("=>"+id+" ->"+e)

        //this.idfichaclinica= event.relatedTarget.id;
        this.formdataResposta.resposta= resposta;
        if(idpergunta){

     //     console.log('update resposta' + "id_ficha_clinica-> " + this.idfichaclinica + " text obs->" + this.formdataResposta.resposta)

          await http.put('/fichaclinica/resposta/'+idpergunta, this.formdataResposta).then(resp => {
            console.log(resp)
          })
      }
        }catch(err){
          alert(err.data ? err.data.message : 'Não foi possível salvar a ficha')
        }
    },

    async saveObservacao(item,id) {

      try {

console.log(item+"<--->"+id)


console.log('-------------------')
        this.idfichaclinica2 = id;
        this.formdataObservacao.observacao = item.target._value;

        if(this.idfichaclinica2) {

             await http.put('/fichaclinica/observacao/'+this.idfichaclinica2, this.formdataObservacao).then(resp => {
             console.log(resp)
          })
        }

      } catch (err) {
        alert(err.data ? err.data.message : 'Não foi possível salvar a ficha')
      }
    },

    imprimirAso(item) {
      //pesquisa os exames
      http.get('/examesaso/' + item.id).then(response => {
        this.examesAso = response.data
        //  this.modalShow = true;
        item['procedimentos'] = this.examesAso
        this.dadosAso = item
      })
    },

    editItem(item) {
         this.modalShow = true;
         this.ActionFindPerguntas(item.id)
    },

    mascaraCnpj(value) {
      var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      return mascara;
    },
    formatarData: function (value) {
      if (value === null) { return null }
      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    }
  }
}
</script>
<style >
.fonteLinhas {
  font-size: 14px;
  text-align: center
}

.fonteLinhasLeft {
  font-size: 14px;

}

.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}

.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select {
  position: relative;
  margin-top: 20px;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;

  @if $columns {
    flex-direction: column;
  }
}
</style>