<template>

  <div>
    <b-row>
        <b-col>
           &nbsp;&nbsp;
           <label class="fontNegrito">Empresa: &nbsp;</label> 
           <label class="fontNegrito16"> {{ empresa.nome }}</label> 
        </b-col>
   </b-row>
    <b-row>
      <b-col>
        <p align="left">
          <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Pesquise aqui...">
              </b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </p>
      </b-col>
     
      <b-col>
        <p align="left" class="pesquisa_select">
          <b-button @click="createItem" pill variant="success" size="sm">Novo Registro</b-button>
          <!-- <b-button @click="pesquisarFuncao" pill variant="success" size="sm"> Pesquisar </b-button> -->
            &nbsp;
                   <b-button  type="button" scale="2" variant="outline-success"  v-on:click="download" ><b> Excel</b> <b-icon icon="file-excel" ></b-icon></b-button>
        </p>
      </b-col>
    </b-row>

    <b-table striped hover responsive id="my-table" :busy="isBusy" :items="tableConvenioEmpresas" :filter="filter"
      :fields="visibleFields" :per-page="perPage" :current-page="currentPage" aria-controls="my-table" small>

      <template #cell(index)="data">{{ data.index + 1 }}</template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(opcao)="data">
        <b-icon icon="pencil-fill" cursor="pointer" @click="editItem(data.item)" pill variant="primary" size="sm">
        </b-icon>
        &nbsp;
        <b-icon icon="trash" cursor="pointer" @click="deleteItem(data.item)" pill v-b-modal="'edit-modal'"
          variant="danger" size="sm">
        </b-icon>
      </template>

    </b-table>

    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>

    <!-- Info modal -->
    <b-modal v-model="modalShow" hide-footer>
      <b-form @submit.prevent="save">
                  <b-form-group id="input-group-2" label="" label-for="input-2">
                       <b-form-input
                                id="input-2"
                               hidden="true"
                                v-model="formdata.id"
                                placeholder="id"
                        ></b-form-input>
                        <b-form-input
                                id="input-2"
                                hidden="true"
                                v-model="formdata.empresa_id"
                                placeholder="empresa_id"
                        ></b-form-input>
                   </b-form-group>

              <b-form-group class="mb-3" label="Exame:">
                <v-select :options="exame" :reduce="(option) => option.id" label="name"
                  v-model="formdata.exame_id"></v-select>
              </b-form-group>

              <b-form-group id="input-group-3" label="valor Exame:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valorexame"
                              required
                              placeholder="valorexame"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
              </b-form-group>


               <b-form-group id="input-group-3" label="valorems:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valorems"
                              required
                              placeholder="valorems"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
                </b-form-group>

                 <b-form-group id="input-group-3" label="valormedico:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.valormedico"
                              required
                              placeholder="valormedico"
                              :formatter="formatNumeroToReal"
                            ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label="Ativo:" label-for="checkbox-1" size="sm" switch>
                         <b-form-checkbox class="mb-3" id="checkbox-1" v-model="formdata.ativo" required placeholder="ativo"></b-form-checkbox>
                  </b-form-group>

        <br />
        <b-button size="sm" pill variant="danger" @click="close">
          Cancelar
        </b-button>
        &nbsp;
        <b-button type="submit" pill size="sm" variant="success">
          Salvar
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import { http } from '@/plugins/axios'
import {  mapActions, mapState } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import * as XLSX from 'xlsx'
export default {

        mixins:[maskMixins], 
        data () {

          return {
              selected: '',
              convenioEmpresas:[],
              exame:{},
              empresa:{},
              idempresa: null,
              isBusy: false,
              perPage: 30,
              currentPage: 1,
              filter: null,
              infoModal: {
                        id: 'info-modal',
                        title: '',
                        content: ''
                        },
              fields:[  
                      {
                        key:'index',
                        label:'Indice',
                        visible: true,
                        formatter: 'index'
                      },
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: false,
                        thStyle: { width: "2%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'exame.name',
                        label:'Exame',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "30%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'valorexame',
                        label:'Valor Exame',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                     {
                        key: 'valorems',
                        label:'Valor Ems',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'valormedico',
                        label:'Valor Colaborador',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft',
                        formatter: 'convertNumberToReal'
                      },
                      {
                        key: 'ativo',
                        label:'Ativo',
                        formatter: 'trueParaSim',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'opcao',
                        visible: true,
                        label:'Opções',
                        sortable: true
                      }
                                    
          ],
             formdata: {},
             modalShow: false,
             editedIndex: -1
          }
        },
      
        created(){
          
                     this.formdataPesquisa = {
                          empresa_id: this.$route.query.empresa_id
                     }

                      this.ActionFindConvenioByEmpresa(this.formdataPesquisa),
                      this.ActionFindExame()
                      
    
                },
        mounted() {


          this.buscaEmpresa(this.formdataPesquisa.empresa_id)

        },


      computed: {
           ...mapState('auth', ['user']),
           
           visibleFields(){
                              return this.fields.filter(field => field.visible)
                          },
                  
                    rows(){
                              return this.tableConvenioEmpresas.length
                          },
                           ...mapState('exame', ['tableExame']),
                           ...mapState('convenioempresa', ['tableConvenioEmpresas'])
        },

        methods: {
                   ...mapActions('auth', ['ActionSignOut']),
                   ...mapActions('exame', ['ActionFindExame']),
                   ...mapActions('convenioempresa', ['ActionSalvar']),
                   ...mapActions('convenioempresa', ['ActionEditar']),
                   ...mapActions('convenioempresa', ['ActionDeletar']),
                   ...mapActions('convenioempresa', ['ActionFindConvenioByEmpresa']),

    download: function () {
      const data = XLSX.utils.json_to_sheet(this.tableConvenioEmpresas)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'table_convenioEmpresa.xlsx')
    },



            createItem() {

                        this.modalShow = true;
                        this.formdata = {
                           ativo:'true',
                           exame_id: '',
                           empresa_id: this.formdataPesquisa.empresa_id
                        }
                        this.exame = this.tableExame
                        this.editedIndex = -1;
                    },

                    editItem(item) {

                        item.valorexame=this.converteFloatMoeda(item.valorexame);
                        item.valorems=this.converteFloatMoeda(item.valorems);
                        item.valormedico=this.converteFloatMoeda(item.valormedico);
                       
                        this.modalShow = true;
                        this.editedIndex = this.tableConvenioEmpresas.indexOf(item);
                        this.formdata = Object.assign({}, item);
                        this.exame = this.tableExame;
                    },

                    close() {
                              this.modalShow = false;
                              setTimeout(() => {
                              this.formdata = {}
                              this.editedIndex = -1;
                              this.ActionFindConvenioByEmpresa()
                            }, 300);
                     },

              async save() {

                      try {
                      this.formdata.valorexame=this.converteMoedaFloat(this.formdata.valorexame);
                      this.formdata.valorems=this.converteMoedaFloat(this.formdata.valorems);
                      this.formdata.valormedico=this.converteMoedaFloat(this.formdata.valormedico);
                      this.formdata.user_id = this.user.id
                      this.formdataPesquisa = {
                          empresa_id: this.formdata.empresa_id
                        }

                            if(!this.formdata.id){//entra para salvar
                                await this.ActionSalvar(this.formdata)
                                this.ActionFindConvenioByEmpresa(this.formdataPesquisa)
                                alert('Cadastrado com sucesso!')
                            }

                            else{//entra para editar

                            delete this.formdata.user
                            await this.ActionEditar(this.formdata)

                             // console.log(this.formdataPesquisa.empresa_id)
                              this.ActionFindConvenioByEmpresa(this.formdataPesquisa)
                                  alert('Atualizado com sucesso!')
                            }

                            this.formdata = {} 
                            this.formdata.empresa_id= this.formdataPesquisa.empresa_id
                           this.formdata.ativo='true'



                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar')
                        }
                },  
              
              async deleteItem(data){

                            const index = this.tableConvenioEmpresas.indexOf(data);  

                            if(confirm('Deseja excluir o item?') && this.tableConvenioEmpresas.splice(index, 1) ){
                                await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                      },

              async buscaEmpresa(idempresa){

                await http.get('/empresa/pesquisaempresaid/'+idempresa).then(resp => {
                  this.empresa = resp.data;

                })

              },   

                  resetInfoModal() {
                       this.infoModal.title = ''
                       this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fontNegrito {
  font-size: 14px;
  font-weight: bold;
}
.fontNegrito16 {
  font-size: 16px;
  font-weight: bold;
}
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}
</style>