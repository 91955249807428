<template>
 <div>

   <b-row>
       <b-col>
          <p align="left">
            <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                   <b-form-input name="filter-input_cnpj" id="filter-input_cnpj" v-model="filter2"   v-on:keyup="searchPacienteCpf"  type="search" placeholder="Pesquise aqui CPF do paciente"></b-form-input>
                     <b-input-group-append>
                          <b-button :disabled="!filter2" @click="filter2 = ''">Limpar</b-button>
                     </b-input-group-append>
                        &nbsp;   &nbsp;   &nbsp;
                      <b-button @click="createItem" pill variant="success" size="sm">Novo Registro</b-button>
                        &nbsp;   &nbsp;
                      <b-button  type="button" scale="2" variant="outline-success"  v-on:click="download" ><b> Excel</b> <b-icon icon="file-excel" ></b-icon></b-button>
                </b-input-group>
              <br> 
                 <b-input-group size="sm">
                  <b-form-input name="filter-input" id="filter-input" v-model="filter"  v-on:keyup="searchPaciente"  type="search" placeholder="Pesquise aqui Nome do Paciente"></b-form-input>
                       <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                       </b-input-group-append>
                  </b-input-group>
                  
              </b-form-group>
          </p>
        </b-col>
        <b-col> &nbsp; </b-col>
        <b-col> &nbsp; </b-col>
   </b-row>
   <b-table striped hover responsive
       id="my-table"
      :busy="isBusy"
      :items="listPacientes" 
      :filter="filter"
      :fields="visibleFields"
      :per-page="perPage"
      :current-page="currentPage"
      aria-controls="my-table"
      small
      >
          <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
          </template>

          <template #cell(opcao)="data">
              <router-link class="home-link" :to="{ name: 'aso', query:{paciente_id: data.item.id, convenio_empresa:data.item.empresa.convenio } }">
                <b-icon icon="plus-square" :class="statusVidibleIcon(data.item)" cursor="pointer" title="Gerar ASO" variant="success" font-scale="1.2"></b-icon>
              </router-link>
              &nbsp;
              <b-icon icon="pencil-fill" 
                  cursor= "pointer" 
                  @click="editItem(data.item)" pill 
                  variant="primary" 
                  size="sm">
              </b-icon>
                  &nbsp;
              <b-icon icon="trash" 
                  cursor= "pointer" 
                  @click="deleteItem(data.item)" pill 
                  v-b-modal="'edit-modal'" 
                  variant="danger" 
                  :hidden="iconVisible(user.perfil)"
                  size="sm">
              </b-icon>
          </template>

            <template #cell(status)="data">
                <b-icon
                :icon="recebeIcon(data.item)" 
                 cursor= "pointer" 
                :variant="statusCorIcon(data.item)" 
                  size="sm">
                </b-icon>
            </template>

    </b-table>

         <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          ></b-pagination>

        <!-- Info modal -->
 <b-modal v-model="modalShow" size="lg"  hide-footer>

     <b-form @submit.prevent="save">

      <b-form-group class="mb-12"  label="Empresas:*" >
          <v-select :options="empresas" :reduce="(option) => option.id" label="nome" v-model="formdata.empresa_id"></v-select>
        </b-form-group>

        <b-form-group class="mb-3" label="Função:*">
          <v-select :options="funcoes" :reduce="(option) => option.id" label="name" v-model="formdata.funcao_id"></v-select>
        </b-form-group>

        <b-form-group class="mb-3" label="Categoria Trabalhadores:*">
          <v-select :options="categoriatrabalhadores" :reduce="(option) => option.id" label="descricao" v-model="formdata.categoriatrabalhador_id"></v-select>
        </b-form-group>

         <b-form-group id="input-group-2" label="Nome:" label-for="input-2">            
             <b-form-input
                   id="input-2"
                   hidden="true"
                   v-model="formdata.id"
                   placeholder="id"
             ></b-form-input>

             <b-form-input
                   id="input-2"
                   v-model="formdata.nome"
                   required
                   placeholder="Nome"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Cpf:*" label-for="input-2">
              <b-form-input
                              id="input-2"
                              v-mask="'###.###.###-##'"
                              v-model="formdata.cpf"
                              required
                              placeholder="Cpf"
              ></b-form-input>
          </b-form-group>

          <b-form-group id="sexo" label="Sexo:*">
                    <b-form-select
                      id="sexo"
                      v-model="formdata.genero"
                      :options="sexos"
                      required
                    ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-2" label="Rg:*" label-for="input-2">
              <b-form-input
                              id="input-2"
                              v-model="formdata.rg"
                              required
                              placeholder="Rg"
               ></b-form-input>
         </b-form-group>

         <b-form-group class="mb-3" label="Nacionalidade:*" >
                <b-form-select  id="nacionalidade" name="nacionalidade" v-model="formdata.nacionalidade_id" >
                    <template #first>
                       <b-form-select-option :value="null" disabled>Selecione uma Nacionalidade</b-form-select-option>
                    </template>
                    <b-form-select-option
                                    v-for="(item,index) in nacionalidades"
                                    v-bind:key="index.id"
                                    v-bind:value="item.id"
                                  >
                                  {{ item.nomepais }} 
                    </b-form-select-option>
                </b-form-select>
         </b-form-group>

         <b-form-group id="input-group-2" label="Telefone:*" label-for="input-2">
              <b-form-input
                              id="input-2"
                              v-mask="'(##) #####-####'"
                              v-model="formdata.telefone"
                              required
                              placeholder="Telefone"
              ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Data Nascimento:" label-for="input-2">
                 
               <date-picker v-model="formdata.datanascimento" :config="options"></date-picker>
                 <!--   <b-form-datepicker 
                    id="input-2"
                    show-decade-nav
                    v-model="formdata.datanascimento"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    required 
                 ></b-form-datepicker >
                -->
          </b-form-group>

          <b-form-group id="input-group-2" label="Endereço:" label-for="input-2">
                  <b-form-input
                      id="input-2"
                      v-model="formdata.endereco"
                      placeholder="Endereço"
                   ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Email:" label-for="input-2">
                      <b-form-input
                              id="input-2"
                              v-model="formdata.email"
                              placeholder="Email"
                      ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Matricula E-Social:" label-for="input-2">            
                       <b-form-input
                           id="input-2"
                           v-model="formdata.matricula"
                       ></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="Data Entrada Empresa:" label-for="input-2">
                       <date-picker v-model="formdata.dataentradaempresa" :config="options"></date-picker>
          <!--        
            <b-form-datepicker 
                            id="input-2"
                            show-decade-nav
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            v-model="formdata.dataentradaempresa"
                            placeholder="Data Entrada Empresa"
                        ></b-form-datepicker >
         -->
          </b-form-group>

          <b-form-group id="input-group-2" label="Descrição Atividade:(opcional)" label-for="input-2">            
                          <b-form-input
                                  id="input-2"
                                  v-model="formdata.descricaoatividade"
                                  placeholder="Descrição da atividade na empresa"
                          ></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="CTPS Carteira de Trabalho (opcional):" label-for="input-2">            
                          <b-form-input
                                  id="input-2"
                                  v-model="formdata.ctps"
                                  placeholder="CTPS carteira de trabalho"
                          ></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="NIS (opcional):" label-for="input-2">            
                          <b-form-input
                                  id="input-2"
                                  v-model="formdata.nis"
                                  placeholder="Nis do trabalhador"
                          ></b-form-input>
         </b-form-group>

         <b-form-group id="input-group-2" label="Tipo Sanguineo (opcional):" label-for="input-2">            
                          <b-form-input
                                  id="input-2"
                                  v-model="formdata.tiposanguineo"
                                  placeholder="Tipo sanguineo"
                          ></b-form-input>
         </b-form-group>

               <br/>
              <b-button size="sm" pill variant="danger" @click="close" >
                  Cancelar
              </b-button>
              &nbsp;
              <b-button type="submit" pill size="sm" variant="success">
                  Salvar
              </b-button>
          </b-form>
      </b-modal>
        
  </div>
</template>
<script>

import { http } from '@/plugins/axios'
import {  mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import maskMixins2 from '@/helpers/mixins/mask2'

import * as XLSX from 'xlsx'
const moment = require("moment");
export default {

     mixins:[maskMixins, maskMixins2], 
        data () {
          return {
             options: {
              format: 'DD/MM/YYYY',
              useCurrent: false,
              locale: moment.locale('pt-br')
              
            },

              select:'',
              isBusy: false,
              perPage: 20,
              currentPage: 1,
              filter: null,
              filter2: '',
              empresas:{},
              funcoes:{},
              nacionalidades:{},
              categoriatrabalhadores:{},
              infoModal: {
                        id: 'info-modal',
                        title: '',
                        content: ''
                        },
              sexos: [
                        { text: "Selecione", value: null },
                        "Masculino",
                        "Feminino",
                        "Outros",
                      ],
              fields:[  
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "2%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'status',
                        visible: true,
                        label: 'Status',
                        sortable: true
                      },
                      {
                        key: 'nome',
                        label:'Paciente',
                        formatter: 'todasMaiusculas',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "30%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'cpf',
                        label:'Cpf',
                        formatter: 'mascaraCpf',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'rg',
                        label:'Rg',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'telefone',
                        label:'Telefone',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                     
                      {
                        key: 'datanascimento',
                        label:'Data Nascimento',
                        formatter: 'formatarDataEntradaParaGrid',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                        {
                        key: 'endereco',
                        label:'Endereço',
                        sortable: true,
                        visible: false,
                        thStyle: { width: "20%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                          {
                        key: 'email',
                        label:'Email',
                        sortable: true,
                        visible: false,
                        thStyle: { width: "15%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'opcao',
                        visible: true,
                        label:'Opções',
                        sortable: true
                      }
                                    
          ], 
             visibleBtExames: 'visible',
             listPacientes: [],
             formdata:{},
             modalShow: false,
             editedIndex: -1
          }
        },
       
       mounted(){  
                  this.ActionFindNacionalidade(),
                  this.ActionFindFuncaoCbo(),
                  this.ActionFindEmpresaCnpj(),
                  this.ActionFindPaciente(),
                  this.ActionFindFuncao(),
                  this.ActionFindCategoriaTrabalhador(),
                  setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                            this.isBusy = false
                            }, 2.0*2000)
        },
        computed:{
                  ...mapState('auth', ['user']),
                  visibleFields() {
                                      return this.fields.filter(field => field.visible)
                                  },
                  rows() {
                            return this.listPacientes.length
                         },
                      
                      ...mapState('funcao', ['tableFuncoesCbo']),
                      ...mapState('funcao', ['tableFuncoes']),
                      ...mapState('empresa', ['tableEmpresasCnpj']),
                      ...mapState('paciente',['tableCategoriaTrabalhadores']),
                      ...mapState('paciente',['tableNacionalidade'])
        },
        methods: {
                   ...mapActions('funcao', ['ActionFindFuncaoCbo']),
                   ...mapActions('funcao', ['ActionFindFuncao']),
                   ...mapActions('empresa', ['ActionFindEmpresaCnpj']),
                   ...mapActions('paciente', ['ActionFindPaciente']),
                   ...mapActions('paciente', ['ActionFindCategoriaTrabalhador']),
                   ...mapActions('paciente', ['ActionFindNacionalidade']),
                   ...mapActions('paciente', ['ActionSalvar']),
                   ...mapActions('paciente', ['ActionEditar']),
                   ...mapActions('paciente', ['ActionDeletar']),

                   iconVisible(perfil) {
                      if(perfil=== 'administrador'){
                          return false
                        }
                       else {
                          return true
                        }
                    },

                      async searchPaciente(e) {

                                if (e.target.value.length > 3) {
                                  await http.get('/paciente/consulta/' + e.target.value).then(resp => {
                                    this.listPacientes = resp.data
                                  })
                                }
                        },

                      async searchPacienteCpf(e) {
                        if (e.target.value.length > 3) {
                          await http.get('/paciente/consultacpf/' + e.target.value).then(resp => {
                            this.listPacientes = resp.data
                          })
                        }
                      },

                     download: function () {
                             const data = XLSX.utils.json_to_sheet(this.listPacientes)
                             const wb = XLSX.utils.book_new()
                             XLSX.utils.book_append_sheet(wb, data, 'data')
                             XLSX.writeFile(wb, 'table_pacientes.xlsx')
                             },

                     createItem(){

                        this.modalShow = true;
                        this.formdata = {
                          empresa_id: '',
                          funcao_id:'',
                          categoriatrabalhador_id:''
                        }
                      
                        this.editedIndex = -1;
                        this.empresas = this.tableEmpresasCnpj
                        this.funcoes = this.tableFuncoesCbo
                        this.categoriatrabalhadores = this.tableCategoriaTrabalhadores
                        this.nacionalidades = this.tableNacionalidade
                    
                      },

                    editItem(item){
                    
                      this.modalShow = true;
                      this.editedIndex = this.fields.indexOf(item);
                      this.formdata = Object.assign({}, item);
                      this.formdata.datanascimento = this.formatarDataEntradaParaGrid(this.formdata.datanascimento)
                      this.formdata.dataentradaempresa = this.formatarDataEntradaParaGrid(this.formdata.dataentradaempresa)

                      this.empresas = this.tableEmpresasCnpj
                      this.funcoes = this.tableFuncoes
                      this.categoriatrabalhadores = this.tableCategoriaTrabalhadores
                      this.nacionalidades = this.tableNacionalidade

                    },

                    close(){
                              this.modalShow = false;
                              setTimeout(() => {
                                this.formdata = {}
                                this.editedIndex = -1;
                                this.ActionFindPaciente()
                              }, 300);
                     },

              async save() {

                      try {

                          if (this.formdata.dataentradaempresa== undefined){//entra cso a data ent empresa nao seja informada
                 
                            this.formdata.dataentradaempresa= null                      
                          }


                          this.formdata.dataentradaempresa = this.formatarDataParaBanco(this.formdata.dataentradaempresa);
                          this.formdata.datanascimento = this.formatarDataParaBanco(this.formdata.datanascimento);

                            if(!this.formdata.id){//entra para salvar
                                
       
                                await this.ActionSalvar(this.formdata)
                                this.ActionFindPaciente()
                                alert('Paciente Cadastrado com sucesso!')
                                this.close()
                            }

                            else{//entra para editar

                                 delete this.formdata.empresa;
                                 delete this.formdata.funcao;
                                 delete this.formdata.nacionalidade;
                                 delete this.formdata.categoriatrabalhador;

                                  await this.ActionEditar(this.formdata)
                                  //this.ActionFindPaciente()
                                  alert('Dados Atualizados com sucesso!')
                                  this.close()
                                  this.listPacientes=[]
                            }

                            this.formdata = {} 

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar paciente')
                        }
                },  
              
                     async deleteItem(data){

                            const index = this.listPacientes.data.indexOf(data);  

                            if(confirm('Deseja excluir o item?') && this.listPacientes.data.splice(index, 1) ){
                                await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                      },


                 recebeIcon(item){

      //useremos para importar os pacientes do antigo sistema para esse
      //todos serao inicialmente colocados com essa empresa e essa funcao
                  const funcaoFake= '02149a1a-1624-4fdf-8141-5484b44097c7';
                  const empresaFake = '03133105-4e85-4c83-9e08-44e0404cfaaf';
                           
                   if((item.empresa_id === empresaFake)|| (item.funca_id === funcaoFake)){
                    this.visibleBtExames = 'invisible';
                             return "x-circle"; 

                   }
              
                  return "check-circle";
            
                          
                      },


                 statusCorIcon(item) {


                    //useremos para importar os pacientes do antigo sistema para esse
                    //todos serao inicialmente colocados com essa empresa e essa funcao
                            const funcaoFake = '02149a1a-1624-4fdf-8141-5484b44097c7';
                            const empresaFake = '03133105-4e85-4c83-9e08-44e0404cfaaf';

                            if ((item.empresa_id === empresaFake) || (item.funca_id === funcaoFake)) {
                                  this.visibleBtExames = 'invisible';
                                  return "danger";

                            }

                            return "success";
               
                      
                        },

                            statusVidibleIcon(item) {
                               
                              
                              //useremos para importar os pacientes do antigo sistema para esse
                              //todos serao inicialmente colocados com essa empresa e essa funcao

                                const funcaoFake = '02149a1a-1624-4fdf-8141-5484b44097c7';
                                const empresaFake = '03133105-4e85-4c83-9e08-44e0404cfaaf';

                                if ((item.empresa_id === empresaFake) || (item.funca_id === funcaoFake)) {
                                 return 'invisible';

                                }

                               return 'visible';


                              },
   
            
                  resetInfoModal() {
                                      this.infoModal.title = ''
                                      this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}

</style>