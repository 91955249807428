<template>
   <div>
     <b-row>
          <b-col  >
            <p align="left" >
              <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                <b-row>
                        <b-col cols="3" sm="3">
                            <b-form-group id="input-group-2" label="Período:"  label-for="input-2">
                                  <b-form-datepicker  class="w-10"
                                    id="input-2"
                                    v-mask="'##/##/####'"
                                    v-model="formdata.datainicio"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    required 
                                    placeholder="DD/MM/YYYY"
                                 ></b-form-datepicker >
                            </b-form-group>
                        </b-col> 
                         <b-col cols="3" sm="3"> 
                              <b-form-group id="input-group-2" label="" label-cols-sm="12"  label-for="input-3">
                                     <b-form-datepicker 
                                        id="input-3"
                                        v-mask="'##/##/####'"
                                        v-model="formdata.datafim"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        required 
                                        placeholder="DD/MM/YYYY"
                                  ></b-form-datepicker >
                              </b-form-group>
                           </b-col> 
                           <b-col cols="9" sm="3"> 
                              <b-form-group id="input-group-2" label="" label-cols-sm="12"  label-for="input-3">
                                <b-button @click="createItem"  pill variant="success" size="sm">Consultar</b-button>
                              </b-form-group>
                           </b-col> 
                </b-row>
          </b-form-group>
         </p>
          </b-col> 
         
     </b-row>
     <b-table striped hover responsive
         id="my-table"
        :items="tableFechamento"
        :fields="visibleFields"
        :filter="filter" 
        :per-page="perPage"
        :current-page="currentPage"
        aria-controls="my-table"
        small
        >
            <template #cell(opcao)="data">
                <b-icon icon="pencil-fill" 
                    cursor= "pointer" 
                    @click="editItem(data.item)" pill 
                    variant="primary" 
                    size="sm">
                </b-icon>
                    &nbsp;
                <b-icon icon="trash" 
                    cursor= "pointer" 
                    @click="deleteItem(data.item)" pill 
                    v-b-modal="'edit-modal'" 
                    :hidden="iconVisible(user.perfil)"
                    variant="primary" 
                    size="sm">
                </b-icon>
            </template>

      </b-table>

           <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>

          <!-- Info modal -->
           <b-modal v-model="modalShow"  hide-footer>
            <b-form @submit.prevent="save">
                    <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
                        <b-form-input
                                id="input-2"
                                hidden="true"
                                v-model="formdata.id"
                                placeholder="id">
                        </b-form-input>
                        <b-form-input
                                id="input-2"
                                v-model="formdata.name"
                                required
                                placeholder="Nome">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" label="Cbo:" label-for="input-3">
                            <b-form-input
                              id="input-3"
                              v-model="formdata.cbo"
                              required
                              placeholder="Cbo"
                            ></b-form-input>
                    </b-form-group>
                <br/>
                <b-button size="sm" pill variant="danger" @click="close" >
                    Cancelar
                </b-button>
                &nbsp;
                <b-button type="submit" pill size="sm" variant="success">
                    Salvar
                </b-button>
            </b-form>
        </b-modal>
    </div>
</template>


<script>
//import {api} from '@/plugins/axios'
import {  mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import * as XLSX from 'xlsx'
export default {

        mixins:[maskMixins], 

        data () {
          return {
              isBusy: false,
              perPage: 20,
              currentPage: 1,
              filter: null,
              infoModal: {
                        id: 'info-modal',
                        title: '',
                        content: ''
                        },
            fields: [
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "60%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'razaosocial',
                        label:'Empresa',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "60%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'cnpj',
                        label: 'Cnpj',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "20%" },
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'valor',
                        label:'Valor',
                        sortable: true,
                        visible: true,
                        thStyle: { width: "20%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'opcao',
                        visible: true,
                        label:'Opções',
                        sortable: true
                      },
                                    
          ],
             formdata:{},
             modalShow: false,
             editedIndex: -1
          }
        },
       
       mounted(){
            
         this.ActionFindFuncao(),
             setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                  this.isBusy = false
                  }, 2.0*2000)
        },
        computed:{

          ...mapState('auth', ['user']),
           visibleFields() {
                return this.fields.filter(field => field.visible)
              },

           countIndice(item){
             const index = this.tableFechamento.indexOf(item);  
             return index;
              },

           rows() {
                 return this.tableFechamento.length
                  },

                  ...mapState('financeiro', ['tableFechamento'])
        },
        methods: {
                   ...mapActions('financeiro', ['ActionFindFechamento']),
                   ...mapActions('financeiro', ['ActionSalvar']),
                   ...mapActions('financeiro', ['ActionEditar']),
                   ...mapActions('financeiro', ['ActionDeletar']),

        iconVisible(perfil) {
          if (perfil === 'administrador') {
            return false
          }
          else {
            return true
          }
        },
      download: function () {
        
        const data = XLSX.utils.json_to_sheet(this.tableFechamento)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        XLSX.writeFile(wb, 'table_funcoes.xlsx')
        
      },

                    createItem() {
                        this.modalShow = true;
                        this.formdata = {}
                        this.editedIndex = -1;
                    },

                    editItem(item) {

                        this.modalShow = true;
                        this.editedIndex = this.tableFechamento.indexOf(item);
                        this.formdata = Object.assign({}, item);
                    },

                    close() {
                              this.modalShow = false;
                              setTimeout(() => {
                                this.formdata = {}
                                this.editedIndex = -1;
                                this.ActionFindFuncao()
                              }, 300);
                     },

              async save() {

                      try {

                            if(!this.formdata.id){//entra para salvar
                                await this.ActionSalvar(this.formdata)
                                this.ActionFindFuncao()
                                alert('Cadastrado com sucesso!')
                            }

                            else{//entra para editar

                             //     await this.ActionEditar(this.formdata)
                             //     this.ActionFindFuncao()
                             //     alert('Atualizado com sucesso!')

                            }

                            this.formdata = {} 

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar produto')
                        }
                },  
              
              async deleteItem(data){


                            const index = this.tableFuncoes.indexOf(data);  
                
                            if(confirm('Deseja excluir o item?') && this.tableFuncoes.splice(index, 1) ){
                              
                             //   await this.ActionDeletar(data.id)
                             //   this.editedItem = Object.assign({}, data); 

                            }
                            
                      },
                  mascaraCnpj(value) {
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

                  formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
                  resetInfoModal() {
                                      this.infoModal.title = ''
                                      this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}




</style>